<template>
    <div class="fix-full">
        <div class="absolute-full">
            <div class="loading">
                <i class="iconfont icon">&#xe620;</i>
                <p>加载中</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name:"loading",
  data(){
    return{
      time:null
    }
  },
  mounted() {
    let _this=this
    this.time=setTimeout(function(){
      console.log("yans1")
      _this.$store.commit("updateShowLoading",false)
    },4000)
  },
  beforeDestroy(){
    clearTimeout(this.time)
  }
}
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss" scoped>
    @import "~@/assets/style/public.scss";

    .fix-full{
        @extend %fix-full;
        z-index:1;
    }

    .absolute-full{
        @extend %full;
        @extend %flex-center-row;
    }
    .loading{
        width:150px;
        height:150px;
        border-radius:10px;
        background:rgba(0, 0, 0, 0.6);
        text-align:center;
        padding:20px;
        color:#fff;
    }
    .icon{
        display:block;
        font-size:60px;
        transition:0.5s;

        animation: loading 2s linear 0s infinite;
        -webkit-animation: loading 2s linear 0s infinite;
        -o-animation: loading 2s linear 0s infinite;
        -moz-animation: loading 2s linear 0s infinite;
    }

    @keyframes loading{
        from{
            transform:rotate(0deg);
        }
        to{
            transform:rotate(360deg);
        }
    }
    p{
        margin-top:10px;
        font-size: 22px;
    }
</style>
